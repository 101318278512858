













































































































import Vue from "vue";
import { AuthLogin } from "@/interfaces/auth/auth_login";
import { get_password_validator_regex } from "@/utils/global";
import SocialAuthenticationBtn from "@/components/auth/SocialAuthenticationBtn.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  GET_USER_DETAILS,
  LOGIN_USER,
  USER_STATE
} from "@/store/modules/auth/constants";
import {
  GET_ROLE_BY_NAME,
  INIT_COMMON_DATA
} from "@/store/modules/common/constants";
import {
  email,
  maxLength,
  minLength,
  required
} from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import { UserState } from "@/store/modules/auth/interfaces";
import router from "@/router";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { HCMSRoles } from "@/interfaces/data_objects/roles";
import { INIT_INSTITUTE_STATE } from "@/store/modules/institute/constants";
export default Vue.extend({
  name: "AuthLogin",
  components: { SocialAuthenticationBtn },
  data(): AuthLogin {
    return {
      login_form: {
        email: "",
        password: "",
        remember_me: true
      },
      show_password: false
    };
  },
  computed: {
    ...mapGetters("auth", {
      user_data: GET_USER_DETAILS,
      auth_loading: AUTH_LOADING
    }),
    email_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.login_form.email?.$dirty) return errors;
      !this.$v.login_form.email?.required &&
        errors.push(this.$t("rules.required"));
      !this.$v.login_form.email?.email && errors.push(this.$t("rules.email"));
      return errors;
    },
    password_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.login_form.password?.$dirty) return errors;
      !this.$v.login_form.password?.required &&
        errors.push(this.$t("rules.required"));
      !this.$v.login_form.password?.min &&
        errors.push(this.$t("rules.password.min"));
      !this.$v.login_form.password?.max &&
        errors.push(this.$t("rules.password.max"));
      !this.$v.login_form.password?.valid &&
        errors.push(this.$t("rules.password.combine"));
      return errors;
    },
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME
    })
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapMutations("auth", {
      set_user_state: USER_STATE,
      set_auth_loading: AUTH_LOADING
    }),
    ...mapActions("auth", {
      login_user: LOGIN_USER
    }),
    ...mapActions("common", {
      init_common_data: INIT_COMMON_DATA
    }),
    ...mapActions("institute", {
      init_institute: INIT_INSTITUTE_STATE
    }),
    /**
     * FUnction to initialize reset password state
     */
    async reset_password_init() {
      this.set_user_state(UserState.RESET_PASSWORD);
      await router.push("reset-password");
    },
    async submit_login_form() {
      this.$v.$touch();
      // Validating login form
      if (!this.$v.$invalid) {
        const login_payload = { ...this.login_form };
        // Login user
        const login_response = await this.login_user(login_payload);
        // If login successful
        if (login_response) {
          this.$v.login_form?.$reset(); // Reset form
          // Initializing common data => roles, industries
          const init_common = await this.init_common_data();
          if (!init_common) return;
          const institute_role = this.get_role_by_name(
            HCMSRoles["Educational Institution Representative"]
          );
          const user = this.user_data;
          if (!user.is_account_confirmed) {
            this.set_user_state(UserState.ONBOARDING_COMPLETED);
            await router.push("/approval-pending");
          } else if (institute_role.id === user.role_id) {
            this.set_user_state(UserState.INSTITUTE);
            await this.init_institute();
            await router.push("/institute");
          } else {
            this.root_error("Invalid Email/Password");
          }
          this.set_auth_loading(false);
        }
      }
    }
  },
  // Form validations
  validations() {
    return {
      login_form: {
        email: {
          required,
          email: email
        },
        password: {
          required,
          min: minLength(8),
          max: maxLength(16),
          valid: (value: string) => get_password_validator_regex().test(value)
        },
        remember_me: {}
      }
    };
  }
});
